<template>
  <div>
    <v-row>
      <v-col>
        <v-row dense>
          <v-col cols="4"/>
          <v-col cols="8">
            <h1>
              {{ inspecting_user_profile.forum_name }}'s Profile
              <v-btn class="my-3 mx-8" color="primary" v-if="userProfileId == user_profile.id" @click="$router.push('/edit_profile')">Edit</v-btn>
            </h1>
          </v-col>
          <!-- The profile picture section -->
          <v-col cols="4" class="d-flex flex-column align-center" min-width="200px">
            <v-avatar rounded size="200">
                <img
                    :src="
                    inspecting_user_profile.profile_picture ||
                    'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541'
                    "
                />
            </v-avatar>
            <span class="text-caption mt-2" v-if="inspecting_user_profile.flair">{{
        inspecting_user_profile.flair
      }}</span>
          </v-col>
          <!-- Profile Details -->
          <v-col cols="8">
            <v-chip class="mx-1">Sexy</v-chip>
            <v-chip class="mx-1">Handsome</v-chip>
            <v-card class="my-2">
              <v-card-text>
                <v-row v-for="fact in general_info" :key="fact.title">
                  <v-col cols="4">
                    <v-subheader class="justify-end">{{fact.title}}</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      readonly
                      :value="fact.value"
                      solo
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-divider class="my-2"/>
            <h2>Recent Forum Posts</h2>
            <ForumThreadCard
              class="my-4"
              v-for="message in inspecting_user_profile.recent_posts"
              :key="message.message_id"
              :thread="{id: message.thread_id, title: message.title, description: message.content, forum_name: inspecting_user_profile.forum_name, poster_user_id: inspecting_user_profile.id}"
              />
          </v-col>
        </v-row>
      </v-col>
    </v-row>  
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import store from "@/store";

import { FETCH_USER_PROFILE } from "@/store/actions.type";
import { BEGIN_LOADING, END_LOADING } from "@/store/mutations.type";
import ForumThreadCard from "../components/Boards/ForumThreadCard.vue";

export default Vue.extend({
  name: "Profile",
  props: {
    userProfileId: {
        type: Number,
        required: true
    }
  },  
  computed: {
    ...mapGetters([
      "isLoading",
      "inspecting_user_profile",
      "user_profile"
    ]),
    general_info() {
      return [
        {
          title: "Started Playing",
          value: new Date(this.inspecting_user_profile.created_on * 1000).friendlyString()
        }
      ]
    }
  },
  components: { ForumThreadCard },
  created() {
    store.commit(BEGIN_LOADING);
    Promise.all([store.dispatch(FETCH_USER_PROFILE, this.userProfileId)]).then(() => {
      store.commit(END_LOADING);
    });
  },
});
</script>
